export function setItem(key, value) {
  if (typeof window !== `undefined`) {
    const remember = localStorage.getItem('remember')
    if (remember) {
      localStorage.setItem(key, value)
    } else {
      sessionStorage.setItem(key, value)
    }
  }
}

export function  getItem(key) {
  if (typeof window !== `undefined`) {
    const remember = localStorage.getItem('remember')
    if (remember) return localStorage.getItem(key)
    
    return sessionStorage.getItem(key)
  }
  return null
}

export function  clear() {
  if (typeof window !== `undefined`) {
    localStorage.clear()
    sessionStorage.clear();
  }
}