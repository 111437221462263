import React from 'react'
import { Link, navigate } from 'gatsby'
import logo from '../images/encounter-dating.png'
import axios from 'axios'
import * as storage from '../utils/storage'
import { addDays } from '../utils/date'
import moment from 'moment'

export default class Topnav extends React.Component {
  constructor() {
    super()
    this.state = {
      burger_active: false,
      first_name: '',
      loggedin: false,
      showSubmenu: false,
    }
  }

  componentDidMount() {
    let path = window.location.pathname
    let expiredAt = storage.getItem('expired_at')
    if (expiredAt) {
      const isExpired = moment().isSameOrAfter(moment(expiredAt), 'day')
      if (isExpired) {
        this.handleLogout()
      }
    } else {
      if (
        false ===
        [
          '/login',
          '/create-login',
          '/forgot',
          '/create-profile',
          '/signup-login',
          '/reset-password',
        ].includes(path)
      ) {
        this.handleLogout()
      }
    }

    let user = storage.getItem('user')
    console.log(storage.getItem('user'))
    if (user) {
      user = JSON.parse(user)
      this.setState({
        loggedin: true,
        first_name: user.first_name,
      })
      return
    }
    axios
      .post(
        `${process.env.API_BASE_URL}/graphql`,
        {
          query: `query {
              me {
                id
                first_name
                last_name
                email
                age
                gender
                mobile
                location
                photo_url
                public_contacts
              }
            }`,
        },
        {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
          },
        }
      )
      .then(res => {
        const user = res.data.data.me
        storage.setItem('user', JSON.stringify(user))
        storage.setItem('expired_at', addDays(30))
        this.setState({
          loggedin: true,
          first_name: user.first_name,
        })
      })
      .catch(err => console.log(err))
  }

  handleLogout(e) {
    e && e.preventDefault()
    storage.clear()
    this.setState({
      loggedin: false,
      first_name: '',
    })
    navigate('/')
  }

  handleToggleSubmenu(e) {
    e.preventDefault()
    this.setState(state => ({
      showSubmenu: !state.showSubmenu,
    }))
  }

  render() {
    const isLoggedIn = this.state.loggedin
    const { showSubmenu } = this.state
    return (
      <div>
        <nav
          className="navbar is-white"
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: '1.5rem 0rem 0rem 0rem',
            marginBottom: '40px',
          }}
        >
          {/* logo */}
          <div className="logo-container">
            <Link to="/">
              <img alt="logo" src={logo} className="main-logo" />
            </Link>
          </div>
          {/* burger */}
          <div className="navbar-brand level" style={{ position: 'relative' }}>
            {isLoggedIn && (
              <div
                className={
                  this.state.burger_active
                    ? 'navbar-burger level-left burger is-active'
                    : 'navbar-burger level-left burger'
                }
                data-target="navMenuColorwhite-example"
                style={{
                  marginLeft: '0',
                  zIndex: '1000',
                }}
                onClick={() =>
                  this.setState(prevState => ({
                    burger_active: !prevState.burger_active,
                  }))
                }
              >
                <span />
                <span />
                <span />
              </div>
            )}
          </div>
          {/* dropdown content */}
          {isLoggedIn && (
            <div
              id="navMenuColorwhite-example"
              className={
                this.state.burger_active
                  ? 'navbar-menu is-active'
                  : 'navbar-menu'
              }
            >
              <div className="navbar-end">
                <Link className="navbar-item modified" to="/how-it-works">
                  How it Works
                </Link>
                <Link className="navbar-item modified" to="/events">
                  Events
                </Link>
                <Link className="navbar-item modified" to="/faq">
                  FAQ
                </Link>
                <div className="navbar-item has-dropdown is-hoverable is-hidden-touch">
                  <Link
                    className="navbar-link modified"
                    to="#"
                    onClick={e => e.preventDefault()}
                  >
                    {/* {this.state.first_name ? this.state.first_name : 'Account'} */}
                    Account
                  </Link>
                  <div className="navbar-dropdown">
                    <Link
                      className="navbar-item modified"
                      to="/matches-overview"
                    >
                      My Matches
                    </Link>
                    <Link className="navbar-item modified" to="/profile">
                      Profile
                    </Link>
                    <a
                      className="navbar-item modified"
                      href="/"
                      onClick={e => this.handleLogout(e)}
                    >
                      Logout
                    </a>
                  </div>
                </div>
                <div className="navbar-item has-dropdown is-hidden-desktop">
                  <Link
                    className={`navbar-link modified ${
                      showSubmenu ? 'expanded' : ''
                    }`}
                    to="#"
                    onClick={e => this.handleToggleSubmenu(e)}
                  >
                    {/* {this.state.first_name ? this.state.first_name : 'Account'} */}
                    Account
                  </Link>
                  <div
                    className={`navbar-dropdown ${
                      showSubmenu ? 'is-active' : 'is-hidden'
                    }`}
                  >
                    <Link
                      className="navbar-item modified"
                      to="/matches-overview"
                    >
                      My Matches
                    </Link>
                    <Link className="navbar-item modified" to="/profile">
                      Profile
                    </Link>
                    <a
                      className="navbar-item modified"
                      href="/"
                      onClick={e => this.handleLogout(e)}
                    >
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </nav>
      </div>
    )
  }
}
