import 'bulma/css/bulma.css'
import './layout.css'
import 'react-image-lightbox/style.css'
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Topnav from './topnav'
import Footer from './footer'

const Layout = ({ children, homePage }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={homePage ? 'home-page' : ''}>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Encounter Dating App' },
            { name: 'keywords', content: 'Dating, Single events' },
          ]}
          link={[
            {
              rel: 'apple-touch-icon-precomposed',
              type: 'image/png',
              href: '/icons/icon-48x48.png',
              size: '96x96',
            },
            {
              rel: 'apple-touch-icon-precomposed',
              type: 'image/png',
              href: '/icons/icon-72x72.png',
              size: '96x96',
            },
            {
              rel: 'apple-touch-icon-precomposed',
              type: 'image/png',
              href: '/icons/icon-96x96.png',
              size: '96x96',
            },
            {
              rel: 'apple-touch-icon-precomposed',
              type: 'image/png',
              href: '/icons/icon-144x144.png',
              size: '144x144',
            },
            {
              rel: 'apple-touch-icon-precomposed',
              type: 'image/png',
              href: '/icons/icon-192x192.png',
              size: '192x192',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Topnav />
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <div className="content">{children}</div>
        <Footer />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
